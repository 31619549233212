// src/analytics.js
import ReactGA from 'react-ga4';

export const initGA = (measurementId) => {
    ReactGA.initialize(measurementId);
  };

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

export const logEvent = (category = '', action = '', label = '') => {
  if (category && action) {
    ReactGA.event({
      category,
      action,
      label
    });
  }
};

export const logTiming = (category, variable, value, label) => {
  if (category && variable && value) {
    ReactGA.timing({
      category,
      variable,
      value,
      label
    });
  }
};
  
