import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css, createGlobalStyle } from "styled-components";
import { Container, ContentWithPaddingXl } from "./Layouts.js";
import { SectionHeading } from "./Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "./Buttons.js";
import Slider from '@mui/material/Slider';
import { styled as muiStyled } from '@mui/material/styles';
import { Select, MenuItem, InputLabel, FormControl, Checkbox, ListItemText } from '@mui/material';
import { FaUtensils } from 'react-icons/fa';
import { PiCouchFill, PiArmchairFill, PiOfficeChairFill, PiChairFill, PiDeskFill  } from "react-icons/pi";
import { MdTableRestaurant } from "react-icons/md";
import { IoBedSharp } from "react-icons/io5";
import { initGA, logPageView, logEvent } from './analytics';


const HeaderRow = tw.div`
  flex justify-between items-center flex-col xl:flex-row w-full fixed top-0 bg-white left-0 shadow-md z-50
`;
const Header = tw(SectionHeading)`
  pl-0 xl:pl-0 xl:text-left cursor-pointer
`;
const HeaderRowInner = tw.div`
  max-w-screen-xl mx-auto justify-between items-center w-full
`;

const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-2 xl:mt-0`;
const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    text-decoration: inherit;  
    tab-size: 4;
    line-height: 1.5;
  }
  body, html {
    margin: 0;
    border-width: 0px;
    min-height: 100vh;
    padding: 2rem;
    font-family: 'Inter', system-ui;
    background-color: #F3F3F3;
    max-width: 100%; /* Ensure it fits within the viewport */
  }
  blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
    margin: 0;
  }
  
  @media (max-width: 1280px) {
    body, html {
      padding: 1rem;
      padding-top: 2rem;
    }
  }

  @media (max-width: 1024px) {
    body, html {
      padding: 0.75rem;
      padding-top: 1.9rem;
    }
  }

  @media (max-width: 900px) {
    body, html {
      padding: 0.625rem;
      padding-top: 1.5rem;
    }
  }

  @media (max-width: 800px) {
    body, html {
      padding: 0.5625rem;
      padding-top: 1.5rem;
    }
  }

  @media (max-width: 768px) {
    body, html {
      padding: 0.5rem;
      padding-top: 1.5rem;
    }
  }

  @media (max-width: 600px) {
    body, html {
      padding: 0.4rem;
      padding-top: 0.8rem;
    }
  }

  @media (max-width: 480px) {
    body, html {
      padding: 0.35rem;
      padding-top: 0.8rem;
    }
  }

  @media (max-width: 414px) {
    body, html {
      padding: 0.3rem;
      padding-top: 0.8rem;
    }
  }

  @media (max-width: 390px) {
    body, html {
      padding: 0.275rem;
      padding-top: 0.8rem;
    }
  }

  @media (max-width: 375px) {
    body, html {
      padding: 0.26rem;
      padding-top: 0.8rem;
    }
  }

  @media (max-width: 360px) {
    body, html {
      padding: 0.25rem;
      padding-top: 0.8rem;
    }
  }

  @media (max-width: 320px) {
    body, html {
      padding: 0.2rem;
      padding-top: 0.8rem;
    }
  }
`;
 

const TabControl = styled.div`
  ${tw`cursor-pointer px-4 py-2 mt-2 sm:mt-0 sm:mr-3 last:mr-0 text-gray-900 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-3/12 sm:w-auto text-center flex flex-col items-center flex-grow`}
  &:hover {
    ${tw`bg-gray-300 text-gray-900`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
`;

const TabText = styled.div`
  ${tw`mb-2 text-lg sm:text-lg`}
  @media (max-width: 480px) {
    ${tw`text-base`} // Adjust text size for smaller viewports
  }
`;
const StyledPhrase1 = styled.p`
  ${tw`font-semibold text-gray-800`};
  font-size: 1.5rem;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }

  @media (max-width: 414px) {
    font-size: 1.07rem;
  }

  @media (max-width: 390px) {
    font-size: 1.01rem;
  }

  @media (max-width: 375px) {
    font-size: 0.98rem;
  }

  @media (max-width: 360px) {
    font-size: 0.95rem;
  }

  @media (max-width: 320px) {
    font-size: 0.85rem;
  }
`;

const StyledPhrase2 = styled.p`
  ${tw`text-gray-600`};
  font-size: 1.125rem;

  @media (max-width: 414px) {
    font-size: 1.07rem;
  }

  @media (max-width: 390px) {
    font-size: 1.01rem;
  }

  @media (max-width: 375px) {
    font-size: 0.98rem;
  }

  @media (max-width: 360px) {
    font-size: 0.95rem;
  }

  @media (max-width: 320px) {
    font-size: 0.85rem;
  }
`;

const LogoItem = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(43px, 108px));
  grid-auto-rows: auto;
  align-items: center;
  gap: 3px; 
  margin-top: 0.5rem;
  background-color: #F3F3F3; 

  @media (min-width: 1280px) {
    grid-template-columns: repeat(11, 108px);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(11, 87px);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(11, 74.7px);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(11, 66px);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(11, 63.5px);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(11, 48.5px);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(11, 38px);
  }

  @media (max-width: 414px) {
    grid-template-columns: repeat(11, 33.5px);
  }

  @media (max-width: 390px) {
    grid-template-columns: repeat(11, 30.5px);
  }

  @media (max-width: 375px) {
    grid-template-columns: repeat(11, 29.5px);
  }

  @media (max-width: 360px) {
    grid-template-columns: repeat(11, 28px);
  }
  @media (max-width: 320px) {
    grid-template-columns: repeat(11, 25px);
  }
`;

const LogoImage = styled.img`
  max-width: 108px;
  max-height: 43px;
  width: 100%; 
  height: 100%; 
  object-fit: contain;
`;

const NotificationBar = tw.div`absolute left-[calc(50%-150px)] bg-yellow-800 text-white text-[0.85rem] px-1 py-1 rounded-bl-lg shadow-lg inline-block z-50`;


const TabContent = tw(motion.div)`mt-2 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0 flex flex-col h-full`;

const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imagesrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;

const CardButton = styled(PrimaryButtonBase)`
  font-size: 1rem;
  border-radius: 0.5rem;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 300ms;
  cursor: pointer;
  font-family: inherit;
  line-height: inherit;

  ${tw`text-sm`}
`;

const ButikIcon = styled.img`
  ${tw`absolute top-0 right-0 m-2 w-8 h-8`}
  object-fit: contain;
  width: 50px;
  height: 50px;
`;

const CardText = tw.div`p-4 text-gray-900 flex flex-col flex-grow justify-between`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const PageButton = styled.button`
  ${tw`px-4 py-2 mx-1 my-1 rounded-md text-gray-600 transition duration-300 text-sm sm:text-base`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

const PaginationContainer = tw.div`flex flex-wrap justify-center items-center mt-10`;

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 10;
    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <PaginationContainer>
      <PageButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
        Föregående
      </PageButton>
      {getPageNumbers().map((page) => (
        <PageButton
          key={page}
          active={currentPage === page}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </PageButton>
      ))}
      <PageButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
        Nästa
      </PageButton>
    </PaginationContainer>
  );
};

const SortControl = styled.div`
  ${tw`flex justify-end`}
`;

const SortButton = styled.button`
  ${tw`px-4 py-2 mx-1 rounded-md text-gray-600 transition duration-300 text-sm sm:text-base`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;

const FiltersContainer = styled.div`
  ${tw`w-full bg-white rounded-lg shadow-md flex flex-wrap justify-between items-center`}
`;

const ResetButton = styled.button`
  ${tw`px-1 py-0 mx-1 rounded-sm text-red-600 transition duration-300 text-sm sm:text-sm`}
  &:hover {
    ${tw`bg-red-300 text-red-700`}
  }
`;


const FilterGroup = styled.div`
  ${tw`flex justify-between items-center`}
`;

const FilterItem = styled.div`
  ${tw`w-24 p-1`}  // Adjust width to make boxes smaller and add padding for spacing
`;


const SliderWrapper = styled.div`
  ${tw`flex-grow lg:flex-grow-0 lg:w-1/4`}
  padding-left: 7px;
  padding-right: 7px;
`;

const InputWrapper = styled.div`
  ${tw`flex justify-between w-full`}
`;

const PriceInput = styled.input`
  ${tw`w-4/12 border-gray-300 rounded`}
`;

const CustomSlider = muiStyled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 6,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#fff', 
    border: '2px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 6px rgba(0, 0, 0, 0.16)',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 10,
    background: 'unset',
    padding: 0,
    width: 40,
    height: 40,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.primary.main,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));

const tabs = {
  Soffor: 'soffor',
  Fåtöljer: 'fatolj',
  Matbord: 'matbord',
  Stolar: 'stolar',
  Matgrupp: 'matgrupp',
  Skrivbord: 'skrivbord',
  Kontorsstolar: 'kontorsstol',
  Sängar: 'sangar'
};

const filterConfig = {
  'soffor': ['minPrice', 'maxPrice', 'NrOfPlaces', 'Company'],
  'fatolj': ['minPrice', 'maxPrice', 'Company'],
  'matbord': ['minPrice', 'maxPrice', 'NrOfPlaces', 'Company'],
  'stolar': ['minPrice', 'maxPrice', 'Company'],
  'matgrupp': ['minPrice', 'maxPrice', 'NrOfPlaces', 'Company'],
  'skrivbord': ['minPrice', 'maxPrice', 'Company'],
  'kontorsstol': ['minPrice', 'maxPrice', 'Company'],
  'sangar': ['minPrice', 'maxPrice', 'Size', 'Company']
};

const filterOptions = {
  nrofplaces: {
    Soffor: ['1', '2', '3', '4', '5', '6', '7', '8'],
    Matbord: ['2', '4', '6', '8', '10', '12', '14'],
    Matgrupp: ['2', '4', '6', '8', '10']
  },
  company: ['IKEA', 'MIO', 'Jysk', 'HEMTEX', 'Nordicnest', 'Svenssons', 'Bygghemma', 'rum21', 'RoyalDesign', 'FurnitureBox', 'Stalands', 'NordiskaGalleriet', 'Trendrum', 'Sleepo', 'folkhemmet', 'ILVA', 'kilamobler', 'SEmobler', 'Nordiskarum', 'ReformaSthlm', 'Mobelmastarna'],
  size: ['80x200', '90x200', '105x200', '120x200', '140x200', '150x200', '160x200', '180x200']
};

const tabIcons = {
  Soffor: PiCouchFill,
  Fåtöljer: PiArmchairFill,
  Matbord: MdTableRestaurant,
  Stolar: PiChairFill,
  Matgrupp: FaUtensils,
  Skrivbord: PiDeskFill,
  Kontorsstolar: PiOfficeChairFill,
  Sängar: IoBedSharp,
};



function useRandomIncrementCounter() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const stockholmOffset = 2; // Adjust according to daylight savings if necessary
    const currentDate = new Date();
    const currentHour = currentDate.getUTCHours() + stockholmOffset;
    const currentMinutes = currentDate.getUTCMinutes();
    const minutesSinceMidnight = currentHour * 60 + currentMinutes;

    let increment = 0;
    if (minutesSinceMidnight > 0) {
      for (let i = 0; i < minutesSinceMidnight; i += (5 + Math.floor(Math.random() * 3.5))) {
        increment++;
      }
    }

    setCount(increment);

    const interval = setInterval(() => {
      const now = new Date();
      const hours = now.getUTCHours() + stockholmOffset;
      const minutes = now.getUTCMinutes();
      const totalMinutes = hours * 60 + minutes;

      if (totalMinutes === 0) {
        setCount(0);
      } else {
        setCount((prevCount) => prevCount + 1);
      }
    }, 60000 * (5 + Math.floor(Math.random() * 3.5)));

    return () => clearInterval(interval);
  }, []);

  return count;
}





function App() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    minprice: '',
    maxprice: '',
    nrofplaces: [],
    company: [],
    size: []
  });
  const [sort, setSort] = useState('id2');

  const [tempFilters, setTempFilters] = useState({
    minprice: '',
    maxprice: '',
    nrofplaces: [],
    company: [],
    size: []
  });

  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);
  const itemsPerPage = 20;

  const [value, setValue] = useState([]);
  const [minMax, setMinMax] = useState({ min: 0, max: 1000000 });
  const prevMinMax = useRef({ min: minMax.min, max: minMax.max });
  const helpedCount = useRandomIncrementCounter();

  useEffect(() => {
    initGA('G-PNDM1X8XYR');
    logPageView();
  }, []);

  const fetchData = useCallback((endpoint, page) => {
    setLoading(true);
    const query = new URLSearchParams({
      page: page,
      limit: itemsPerPage,
      sort: sort,
      ...(filters.minprice && { minprice: filters.minprice }),
      ...(filters.maxprice && { maxprice: filters.maxprice }),
      ...(filters.nrofplaces.length > 0 && { nrofplaces: filters.nrofplaces.join(',') }),
      ...(filters.company.length > 0 && { company: filters.company.join(',') }),
      ...(filters.size.length > 0 && { size: filters.size.join(',') })
    });

    logEvent('Query', 'Fetch Data', `${endpoint}?${query.toString()}`);
    fetch(`https://allamobler.se/${endpoint}?${query.toString()}`)
      .then(response => response.json())
      .then(data => {
        setData(data.results);
        setLoading(false);
        setTotalPages(Math.ceil(data.totalCount / itemsPerPage));
        if (data.minPrice !== undefined && data.maxPrice !== undefined) {
          const minMaxChanged = prevMinMax.current.min !== data.minPrice || prevMinMax.current.max !== data.maxPrice;
          const filtersChanged = 
            (filters.nrofplaces.sort().toString() !== prevMinMax.current.filters?.nrofplaces.sort().toString()) || 
            (filters.company.sort().toString() !== prevMinMax.current.filters?.company.sort().toString()) || 
            (filters.size.sort().toString() !== prevMinMax.current.filters?.size.sort().toString());

          if ((filters.minprice === '' && filters.maxprice === '') || !minMaxChanged) {
            setMinMax({ min: data.minPrice, max: data.maxPrice });
            prevMinMax.current.min = data.minPrice;
            prevMinMax.current.max = data.maxPrice;
          } else if (!minMaxChanged && filtersChanged) {
            setMinMax({ min: data.minPrice, max: data.maxPrice });
            prevMinMax.current.min = data.minPrice;
            prevMinMax.current.max = data.maxPrice;
          }
          setValue([data.minPrice, data.maxPrice]);
          prevMinMax.current.filters = {
            nrofplaces: filters.nrofplaces,
            company: filters.company,
            size: filters.size
          };
        }
      })
      .catch(error => {
        setLoading(false);
      });
  }, [filters, sort, itemsPerPage]);

  useEffect(() => {
    fetchData(tabs[activeTab], page);
  }, [activeTab, page, filters, sort, fetchData]);

  const handleRangeChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRangeChangeCommitted = (event, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      minprice: newValue[0],
      maxprice: newValue[1],
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTempFilters(prevTempFilters => ({ ...prevTempFilters, [name]: value }));
  };

  const handleInputBlurOrEnter = (event) => {
    if (event.type === 'blur' || event.key === 'Enter') {
      const { name, value } = event.target;
      setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
      setTempFilters(prevTempFilters => ({ ...prevTempFilters, [name]: value }));
    }
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [filterName]: value }));
    logEvent('Filter', 'Change', `${filterName}: ${value}`); // Track filter changes
  };

  const renderFilters = () => {
    const activeFilters = filterConfig[tabs[activeTab]] || [];
    return activeFilters.map(filter => {
      const filterKey = filter.toLowerCase();
      let filterLabel = '';
      if (filter === 'NrOfPlaces') {
        filterLabel = 'Sits';
      } else if (filter === 'Company') {
        filterLabel = 'Butik';
      } else if (filter === 'Size') {
        filterLabel = 'Storlek';
      }
      
      if (filterLabel) {
        const options = filter === 'NrOfPlaces' ? (filterOptions.nrofplaces[activeTab] || []) : (filterOptions[filterKey] || []);
        return (
          <FilterItem key={filterKey}>
            <FormControl fullWidth>
              <InputLabel>{filterLabel}</InputLabel>
              <Select
                multiple
                value={filters[filterKey]}
                onChange={e => handleFilterChange(filterKey, e.target.value)}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                    },
                  },
                }}
              >
                {options.map(option => (
                  <MenuItem key={option} value={option}>
                    <ListItemText primary={option} />
                    <Checkbox checked={filters[filterKey].indexOf(option) > -1} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FilterItem>
        );
      }
      return null;
    });
  };
  

  const resetFilters = () => {
    setFilters({
      minprice: '',
      maxprice: '',
      nrofplaces: [],
      company: [],
      size: []
    });
  };

  const resetSort = () => {
    setSort('id2');
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setPage(1);
    resetFilters();
    resetSort();
    logEvent('Navigation', 'Tab Click', tabName); // Track tab clicks
  };

  const handleHeaderClick = () => {
    setActiveTab(tabsKeys[0]);
    setPage(1);
    resetFilters();
    resetSort();
  };

  const handleSortChange = (newSort) => {
    setSort(newSort);
    setPage(1); // Reset to page 1 when sort changes
  };

  const handleProductClick = (productID, category, minprice, maxprice, company, nrofplaces, size, sort, page) => {
    const clickData = {
      Tab: category, 
      productID: productID,
      minprice: minprice,
      maxprice: maxprice,
      company: company,
      nrofplaces: nrofplaces,
      size: size,
      sort: sort,
      page: page
    };
  
    fetch('https://allamobler.se/click', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(clickData)
    }).catch(error => {
      console.error('Error logging click:', error);
    });
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <GlobalStyle/>
      <Container>
        <ContentWithPaddingXl>
          <HeaderRow>
            <HeaderRowInner>
              <a href="/" onClick={handleHeaderClick} style={{ color: 'inherit', textDecoration: 'none' }}>
                <Header>ALLA MÖBLER</Header>
              </a>
            </HeaderRowInner>
          </HeaderRow>
          <StyledPhrase1>Hitta billiga möbler från olika butiker i Sverige.</StyledPhrase1>
          <StyledPhrase2>Jämför priser på möbler och spara pengar.</StyledPhrase2>
          <LogoItem>
            <LogoImage src="https://upload.wikimedia.org/wikipedia/commons/1/1b/Ikea-logo.png" alt="IKEA Logo" />
            <LogoImage src="https://www.move4u.se/wp-content/uploads/2016/08/mio-logo.png" alt="MIO Logo" />
            <LogoImage src="https://jyskblueline.com/sites/default/files/inline-files/logo-JYSK-jpg.jpg" alt="Jysk Logo" />
            <LogoImage src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Nordic_Nest_Logo.svg/1200px-Nordic_Nest_Logo.svg.png" alt="Nordicnest Logo" />
            <LogoImage src="https://cms-media.rum21.se/faf9caa4-9143-4c6d-a980-19f5d8b81aca_black_rgb_primary_logotype_rum21.png" alt="Rum21 Logo" />
            <LogoImage src="https://4457789.fs1.hubspotusercontent-na1.net/hub/4457789/hubfs/stalands-logo.png?width=400&height=142&name=stalands-logo.png" alt="Stalands Logo" />
            <LogoImage src="https://www.kilamobler.se/wp-content/themes/kilamobler/static/images/logo.png" alt="kilamobler Logo" />
            <LogoImage src="https://files.cdn.ipaper.io/iPaper/Files/Optimize/3ac99fbf-62e2-4907-b45e-f0e0ad7a73d8.png" alt="ILVA Logo" />
            <LogoImage src="https://coda.newjobs.com/api/imagesproxy/ms/clu/xw10/xw108493079wjlx/branding/158895/Bygghemma-Group-logo.png" alt="Bygghemma Logo" />
            <LogoImage src="https://cdn.cookielaw.org/logos/9aba008b-2425-4c18-ac4e-65bfc0b674c9/26cc1833-3acf-47cb-83f9-f632243fed53/83ecdb29-7884-495d-a645-81b018ab0cc0/RoyalDesign_logo_black.png" alt="RoyalDesign Logo" />
            <LogoImage src="https://s3-eu-west-1.amazonaws.com/tpd/logos/5c92c1a87a437100018d1da0/0x0.png" alt="NordiskaGalleriet Logo" />
            <LogoImage src="https://s3-eu-west-1.amazonaws.com/tpd/logos/4b86a9730000640005054acc/0x0.png" alt="Furniturebox Logo" />
            <LogoImage src="https://mb.cision.com/Public/13525/2590060/8281697af4f3a4a4_org.png" alt="Sleepo Logo" />
            <LogoImage src="https://s3-eu-west-1.amazonaws.com/wb-bolt-production/account_853/career/73dc0fc71320520b443565ac3ca7ed32e153c340-gpfyti.png" alt="HEMTEX Logo" />
            <LogoImage src="https://folkhemmet.com/frontend/view_desktop/design/folkhemmet-logo.png" alt="folkhemmet Logo" />
            <LogoImage src="https://www.trendrum.se/images/graphics/dynamic_graphics/logos/www.trendrum.se.png?1476878505" alt="Trendrum Logo" />
            <LogoImage src="https://semobler.se/media/logo/default/se-mattor_4.png" alt="Semobler Logo" />
            <LogoImage src="https://www.studentkortet.se/wp-content/uploads/2024/05/reforma-logo.png" alt="ReformaSthlm Logo" />
            <LogoImage src="https://www.nordiskarum.se/assets/logo_nordiska.png" alt="Nordiskarum Logo" />
            <LogoImage src="https://mnd-assets.mynewsdesk.com/image/upload/c_limit,dpr_auto,f_auto,q_auto:good,w_600/eeuortz947klurz8pejs" alt="Möbelmästarna Logo" />
            <LogoImage src="https://s3-eu-west-1.amazonaws.com/tpd/logos/5a7578647dd4b200014b421c/0x0.png" alt="Svenssons Logo" />
          </LogoItem>
          <TabsControl>
            {tabsKeys.map((tabName, index) => {
              const Icon = tabIcons[tabName];
              return (
                <TabControl
                  key={index}
                  active={activeTab === tabName}
                  onClick={() => handleTabClick(tabName)}
                >
                  <TabText>{tabName}</TabText>
                  {Icon && <Icon size={24} />}
                </TabControl>
              );
            })}
          </TabsControl>        
          <FiltersContainer>
            <SliderWrapper>
              <CustomSlider
                value={value}
                onChange={handleRangeChange}
                onChangeCommitted={handleRangeChangeCommitted}
                valueLabelDisplay="auto"
                min={minMax.min}
                max={minMax.max}
              />
              <InputWrapper>
                <PriceInput 
                  type="number" 
                  value={tempFilters.minprice}
                  name="minprice"
                  onChange={handleInputChange}
                  onBlur={handleInputBlurOrEnter}
                  onKeyDown={handleInputBlurOrEnter}
                  placeholder={`Min: ${filters.minprice}kr`} 
                />
                <PriceInput 
                  type="number" 
                  value={tempFilters.maxprice} 
                  name="maxprice"
                  onChange={handleInputChange}
                  onBlur={handleInputBlurOrEnter}
                  onKeyDown={handleInputBlurOrEnter}
                  placeholder={`Max: ${filters.maxprice}kr`} 
                />
              </InputWrapper>
            </SliderWrapper>
            <FilterGroup>
              {renderFilters()}
              <ResetButton onClick={resetFilters}>
                Ta bort filter
              </ResetButton>
            </FilterGroup>
            <SortControl>
              <SortButton active={sort === 'price_asc'} onClick={() => handleSortChange('price_asc')}>
                Lägsta pris
              </SortButton>
              <SortButton active={sort === 'price_desc'} onClick={() => handleSortChange('price_desc')}>
                Högsta pris
              </SortButton>
              <SortButton active={sort === 'id2'} onClick={() => handleSortChange('id2')}>
                Popularitet
              </SortButton>
            </SortControl>
          </FiltersContainer>
          <NotificationBar>Idag har vi hjälpt {helpedCount} personer att köpa möbler!</NotificationBar>
          <TabContent
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: 'flex'
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: 'none'
              }
            }}
            transition={{ duration: 0.4 }}
            initial="current"
            animate="current"
          >
            {data.length === 0 ? (
              <StyledPhrase1 style={{ minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}>Inga produkter hittades!</StyledPhrase1>
            ) : (
            data.map((item, index) => (
              <CardContainer key={index} onClick={() => handleProductClick(
                item.ID,
                activeTab,
                filters.minprice,
                filters.maxprice,
                filters.company,
                filters.nrofplaces,
                filters.size,
                sort,
                page
              )}>
                <Card
                  className="group"
                  href={item.URL}
                  target="_blank"
                  initial="rest"
                  whileHover="hover"
                  animate="rest"
                >
                  <CardImageContainer imagesrc={item.ImgSource}>
                    <ButikIcon src={item.LogoSrc} alt="Butik Icon" />
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <a href={item.URL} target="_blank" rel="noopener noreferrer">
                        <CardButton>Gå till butik</CardButton>
                      </a>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{item.Name}</CardTitle>
                    <CardContent>{item.Description}</CardContent>
                    {item.NrOfPlaces !== null && item.NrOfPlaces !== undefined && (
                      <CardContent>Sits: <strong>{item.NrOfPlaces}</strong></CardContent>
                    )}
                    {item.Size !== null && item.Size !== undefined && (
                      <CardContent>Storlek: <strong>{item.Size}</strong></CardContent>
                    )}
                    <CardPrice>{item.Price} kr</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            )))}
          </TabContent>
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={setPage}
          />   
        </ContentWithPaddingXl>
        <footer tw="bg-gray-200 py-4 mt-8">
          <div tw="container mx-auto text-center text-gray-600">
            <p>Kontakta oss: info@allamobler.se</p>
            <p>&copy; 2024 ALLAMOBLER.SE</p>
          </div>
        </footer>
      </Container>
    </>
  );
}

export default App;
